import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";

export const fetchCartList = createAsyncThunk(
  "cart/fetchCartList",
  async ({ user_id, session_id }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}user/cart_list`,
        { user_id, session_id },
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

const cartListSlice = createSlice({
  name: "cartList",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCartList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCartList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCartList.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default cartListSlice.reducer;
