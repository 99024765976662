import React from "react";

const PageNotFound = () => {
  return (
    <section className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
           <div className="coming-soon">
            <img src={require("../../Component/assets/images/coming-soon.png")} alt="" className="img-fluid" width={400} />
           </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
