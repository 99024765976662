import React from "react";
import "./OrderSuccess.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useState } from "react";
const OrderSuccess = ({ isOrderPlaced }) => {
  const location = useLocation();
  const [seconds, setSeconds] = useState(15);
  const navigate = useNavigate("");

  const { orderId, paymentId } = location.state;

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 3000);
  // });
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
        navigate("/my_order");
        // window.location.reload();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, navigate]);

  // const handleNavigateHome = () => {
  //   navigate("/");
  //   window.location.reload();
  // };

  return (
    <section className="order_success_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            {isOrderPlaced ? (
              <div className="success_bg_img ">
                <img
                  src={require("../../Component/assets/images/ordersuccess/new_releases.png")}
                  alt="orderSuccess"
                  className="img-fluid"
                  loading="lazy"
                />
                <h4 className="text-dark mt-3">Thank You !</h4>
                <p className="orderDetailsPara">
                  Order Id : #{orderId} <br />
                  Transaction Id : {paymentId}
                </p>
                <h2> Order Placed Successfully </h2>
                <p className="navigate_info mb-0 text-center">
                  <img
                    src={require("../../Component/assets/images/clock.gif")}
                    alt="clock"
                    className="img-fluid"
                    width={20}
                  />{" "}
                  <br /> 00:00:{seconds} <br />
                  We are Navigate
                </p>
                <div className="orderSuccessBtnBox">
                  <Link className="btn back_to_home" to="/">
                    {" "}
                    {/* <BiArrowBack />  */}
                    Back to Home
                  </Link>
                  <Link className="btn back_to_order" to="/my_order">
                    {" "}
                    {/* <BiArrowBack /> */}
                    Go To My Order
                  </Link>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <h1 className="fw-bold">Error</h1>
                <p className="fw-bold">
                  You cannot access the success page directly.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccess;
