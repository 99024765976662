/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import TopNavbar from "./Component/TopNavbar/TopNavbar";
import MyBeShowNavbar from "./Component/MyBeShowNavbar/MyBeShowNavbar";
import { SearchProvider } from "./Context/Search/Search";
import OrderSuccess from "./Component/OrderSuccess/OrderSuccess";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import React, { Suspense, lazy, useEffect, useState } from "react";
import Loader from "./Component/loader/Loader";
import ErrorBoundary from "./Component/error/ErrorBoundary";

// lazy loading for this pages only
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Product = lazy(() => import("./Pages/Product/Product"));
const Footer = lazy(() => import("./Component/Footer/Footer"));
const ProductDetails = lazy(() => import("./Pages/Product/ProductDetails"));
const Cart = lazy(() => import("./Component/cart/cart/Cart"));
const Check = lazy(() => import("./Component/cart/checkout/Check"));
const WishList = lazy(() => import("./Pages/WishList/WishList"));
const Deal_Offer = lazy(() => import("./Pages/Deal&Offer/Deal_Offer"));
const Become_Seller = lazy(() => import("./Pages/Become_Seller/Become_Seller"));
const CustomNavbar = lazy(() => import("./Component/TopNavbar/CustomNavbar"));
const Products = lazy(() => import("./Pages/Category/Products"));
const Policy = lazy(() => import("./Pages/QuickLinks/Policy"));
const Portfolio = lazy(() => import("./Pages/Portfolio/PortFolio"));
const Portfolio_Details = lazy(() =>
  import("./Pages/Portfolio/Portfolio_Details")
);
const TestimonialDetails = lazy(() =>
  import("./Component/reviewTestimonial/TestimonialDetails")
);
const Wallet = lazy(() => import("./Component/Wallet/Wallet"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const Blogs_Details = lazy(() => import("./Pages/Blogs/Blog_Details"));
const Address = lazy(() => import("./Component/cart/address/Address"));
const MyAccount = lazy(() => import("./Component/Profile/MyAccount/MyAccount"));
const MyOrder = lazy(() => import("./Component/Profile/MyOrder"));
const EditAddress = lazy(() => import("./Component/cart/address/EditAddress"));
const Seller = lazy(() => import("./Pages/Become_Seller/Seller/Seller"));
const TaxDetails = lazy(() =>
  import("./Pages/Become_Seller/Seller/TaxDetails")
);
const Invoice = lazy(() => import("./Component/Profile/Invoice/Invoice"));
const DeliveryChallan = lazy(() =>
  import("./Component/Profile/DeliveryChallan/DeliveryChallan")
);
const Faq = lazy(() => import("./Pages/faq/Faq"));
const Register = lazy(() => import("./Component/Register/Register"));
const Notification = lazy(() => import("./Component/notify/Notification"));
const OrderDetails = lazy(() =>
  import("./Component/Profile/Invoice/OrderDetails")
);

// category api

function App() {
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [lastVisitedPage, setLastVisitedPage] = useState("/");

  const location = useLocation();

  // Get last visited page from local storage
  useEffect(() => {
    const lastPage = localStorage.getItem("lastVisitedPage");
    if (lastPage) {
      setLastVisitedPage(lastPage);
    }
  }, []);

  useEffect(() => {
    // Call handleRouteChange when route changes
    handleRouteChange(location);
  }, [location]);

  const handleRouteChange = (location) => {
    // Store the current route in local storage
    localStorage.setItem("lastVisitedPage", location.pathname);
    setLastVisitedPage(location.pathname);
  };

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    // Check if the session variable is present and the current page is not the "Invoice" page
    const shouldReloadOtherPages = sessionStorage.getItem(
      "shouldReloadOtherPages"
    );
    if (
      isMobile &&
      shouldReloadOtherPages &&
      !location.pathname.startsWith("/invoice")
    ) {
      // Reload the page
      window.location.reload();
      // Remove the session variable after reloading
      sessionStorage.removeItem("shouldReloadOtherPages");
    }
  }, [location.pathname]);

  // gts global function

  useEffect(() => {
    // Define gtag function globally if it's not already defined
    if (typeof window.gtag !== "function") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
        console.log("data layer arguments push:", arguments);
      }
      window.gtag = gtag;
    }
  }, []);

  return (
    <SearchProvider>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <MyBeShowNavbar>
            <CustomNavbar />
            <TopNavbar />
          </MyBeShowNavbar>
          <Notification />
          {/* Routing */}
          <Routes>
            <Route path="/loader" element={<Loader />} />
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/product/:id/:name" element={<ProductDetails />}>
              {" "}
            </Route>
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/cart" element={<Cart />} />
            <Route
              path="/checkout"
              element={<Check setIsOrderPlaced={setIsOrderPlaced} />}
            />
            <Route
              path="/order_success"
              element={<OrderSuccess isOrderPlaced={isOrderPlaced} />}
            />
            <Route path="/address" element={<Address />} />
            <Route path="/edit_address/:id" element={<EditAddress />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/become_seller" element={<Become_Seller />} />
            <Route path="/seller" element={<Seller />} />
            <Route path="/tax_details" element={<TaxDetails />} />
            <Route path="/deal_offer" element={<Deal_Offer />} />
            <Route path="/my_account" element={<MyAccount />} />
            <Route path="/my_order" element={<MyOrder />} />
            <Route
              path="/invoice/:id"
              element={<Invoice isSpecificPage={true} />}
            />
            <Route path="/order-details/:id" element={<OrderDetails />} />
            <Route path="/products/:id/:slug_name" element={<Products />} />
            <Route path="/policy/:id/:slug_name" element={<Policy />} />
            {/* Portfolio */}
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/portfolio_details/:id"
              element={<Portfolio_Details />}
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id/:slug_name" element={<Blogs_Details />} />

            {/* testimonial */}
            <Route path="/testimonial" element={<TestimonialDetails />} />
            <Route path="*" element={<Navigate to={lastVisitedPage} />} />
            {/* search  */}
            <Route path="/product" element={<Product />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/registration" element={<Register />} />
            <Route path="/wallet" element={<Wallet />} />
          </Routes>

          {/* Routing */}
          <MyBeShowNavbar>
            <Footer />
          </MyBeShowNavbar>
        </Suspense>
      </ErrorBoundary>
    </SearchProvider>
  );
}

export default App;
