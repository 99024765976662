import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MyBeShowNavbar = ({ children }) => {
  const location = useLocation();
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowNavBar(false);
    } else if (location.pathname === "/registration") {
      setShowNavBar(false);
    } else if (location.pathname === "/invoice/:id") {
      setShowNavBar(false);
    } else if (location.pathname === "/download_invoice") {
      setShowNavBar(false);
    } else if (location.pathname === "/order_success") {
      setShowNavBar(false);
    } else if (location.pathname === "/loader") {
      setShowNavBar(false);
    } else if (location.pathname.includes("/order-details")) {
      setShowNavBar(false);
    } else {
      setShowNavBar(true);
    }
  }, [location]);

  return <div>{showNavBar && children}</div>;
};

export default MyBeShowNavbar;
