// import React, { useEffect, useState } from "react";
import "../../App.css";
import "./Loader.css";
const Loader = () => {
  // image animation
  // const images = [
  //   require("../../Component/assets/images/loader/chair-1.png"),
  //   require("../../Component/assets/images/loader/sofa-1.png"),
  //   require("../../Component/assets/images/loader/chair-2.png"),
  //   require("../../Component/assets/images/loader/sofa-2.png"),
  //   require("../../Component/assets/images/decor_logo.png"),
  // ];

  // var interval = 200;

  // const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   }, interval);
  //   return () => clearInterval(intervalId);
  // }, [images.length, interval]);

  // const text = "DECORSAGA DESIGN";

  return (
    <div className="lazy-loading-main">
      <img
        src={require("../../Component/assets/images/loader/puf-1.gif")}
        alt="loading"
        className="img-fluid Decorsaga_logo"
      />
      {/* {images.map((src, i) => (
        <img
          key={i}
          src={src}
          alt={`Loader  ${i + 1}`}
          className={currentIndex === i ? "active loaderImg" : "hidden"}
          style={{
            transition: `opacity ${interval / 2}ms ease-in-out`,
            opacity: currentIndex === i ? 1 : 0,
          }}
        />
      ))}
      <div className="loaderText">
        <h1 className="running-text">{text}</h1>
      </div> */}
    </div>
  );
};

export default Loader;
