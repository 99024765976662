import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import "./CustomLoginModal.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import "../login/Login.css";
import { toast } from "react-toastify";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
// import { Helmet } from "react-helmet";
import base64 from "base-64";
import BankOfferSke from "../skeleton/bankOfferSke/BankOfferSke";
// import LoginBanner from "./loginBanner/LoginBanner";
const LoginBanner = lazy(() => import("./loginBanner/LoginBanner"));

const CustomLoginModal = ({
  isOpen,
  onClose,
  signUpPageShow,
  checkRegisterOtp,
  registerData,
}) => {
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
  });
  const navigate = useNavigate();
  const [formDataEmail, setFormDataEmail] = useState({
    email: "",
    password: "",
  });
  const [formDataRegister, setFormDataRegister] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    password: "",
    referral: "",
  });
  const [loginStart, setLoginStart] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loginStartEmail, setLoginStartEmail] = useState(false);
  const [loginNewPassword, setLoginNewPassword] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isOtpForgot, setIsOtpForgot] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [registerNew, setRegisterNew] = useState(false);
  const [otp, setOtp] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [registerOtp, setRegisterOtp] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // email forgot password send
  const [emailForgotPassword, setEmailForgotPassword] = useState("");

  const [emailOtp, setEmailOtp] = useState("");
  const [emailotp1, setEmailOtp1] = useState("");
  const [emailotp2, setEmailOtp2] = useState("");
  const [emailotp3, setEmailOtp3] = useState("");
  const [emailotp4, setEmailOtp4] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (signUpPageShow) {
      handleLoinRegisterCondition();
    }
  }, [signUpPageShow]);

  const handleShowRegisterOtp = () => {
    setIsOtpForgot(true);
    setForgotPassword(false);
    setRegisterNew(false);
    setLoginStartEmail(false);
    setLoginStart(false);
    setIsContinue(false);
    setLoginNewPassword(false);
    setEmailOtp(checkRegisterOtp);
    setFormDataRegister({
      fname: registerData.fName,
      lname: registerData.lName,
      mobile: registerData.mobile,
      email: registerData.email,
      password: registerData.password,
      referral: "",
    });
  };

  useEffect(() => {
    if (checkRegisterOtp) handleShowRegisterOtp();
  }, [checkRegisterOtp]);

  const handleClose = () => {
    onClose();
    handleSignUpStartBack();
  };

  const resetTimer = () => {
    setTimer(120);
  };

  // email login handleChange
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormDataEmail({
      ...formDataEmail,
      [name]: value,
    });
  };

  // register
  // const handleChangeRegister = (event) => {
  //   const { name, value } = event.target;
  //   setFormDataRegister({
  //     ...formDataRegister,
  //     [name]: value,
  //   });
  // };

  const handleChangeRegister = (event) => {
    const { name, value } = event.target;
    // console.log("value", value);
    // Regular expression to allow only numeric characters
    const regexNumeric = /^[0-9]*$/;

    // Regular expression to allow only alphabetic characters and spaces
    const regexAlpha = /^[a-zA-Z\s]*$/;

    const newValue = name === "email" ? value.toLowerCase() : value;
    // Check if the value matches the regular expression based on the field name
    if (name === "fname" || name === "lname") {
      if (regexAlpha.test(value)) {
        setFormDataRegister({
          ...formDataRegister,
          [name]: value,
        });
      }
    } else if (name === "mobile") {
      if (regexNumeric.test(value)) {
        setFormDataRegister({
          ...formDataRegister,
          [name]: value,
        });
      }
    } else if (name === "email") {
      setFormDataRegister({
        ...formDataRegister,
        [name]: newValue,
      });
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown); // Cleanup the interval on component unmount
  }, [timer]);

  // Helper function to format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   if (sessionStorage.getItem("isLoggedIn") != null) {
  //     console.log("User is already logged in");
  //     navigate("/");
  //   }
  // }, []);

  const handleLoginStart = async (e) => {
    e && e.preventDefault();
    // Validate phone number
    const isValidPhone = /^\d{10}$/.test(phone); // Assuming a valid phone number is 10 digits

    if (!isValidPhone) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    const finalData = { mobile: phone };
    try {
      const response = await axios.post(
        API_BASE_URL + "user/send_otp",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        setLoginStart(false);
        setIsContinue(true);
        setOtp(response.data.data);
        resetTimer();
      }
    } catch (error) {
      console.log("error occured login phone number", error);
    }
  };

  const handleVerifyOtp = async (e) => {
    const firebaseToken = sessionStorage.getItem("firebaseToken");
    e.preventDefault();
    const finalData = {
      mobile: phone,
      session_id: localStorage.getItem("session_id"),
      firebase_token: firebaseToken,
    };
    console.log("otp login", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "user/otp_login",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        // Extract entered OTP from your form fields
        const enteredOTP = `${otp1}${otp2}${otp3}${otp4}`.trim();
        const serverOTPAsString = String(otp);

        // Compare the entered OTP with the server's OTP
        if (enteredOTP === serverOTPAsString) {
          sessionStorage.setItem("isLoggedIn", "true");
          sessionStorage.setItem("info", JSON.stringify(response.data.data));
          console.log("Server OTP:", serverOTPAsString);
          toast.success(response.data.message);
          const lastVisitedPage = localStorage.getItem("lastVisitedPage");
          setTimeout(() => {
            onClose(); // Close the modal
            if (lastVisitedPage) {
              navigate(lastVisitedPage);
            } else {
              navigate("/"); // Navigate to the home page if no last visited page is found
            }
            window.location.reload();
          }, 2000);
          // onClose();
        } else {
          // Handle case when OTPs do not match
          // setVerifyOtp(true);
          // setIsContinue(false);
          toast.error("Invalid Otp");
        }
      } else {
        const enteredOTP = `${otp1}${otp2}${otp3}${otp4}`.trim();
        const serverOTPAsString = String(otp);
        if (enteredOTP === serverOTPAsString) {
          setVerifyOtp(true);
          setIsContinue(false);
        } else {
          toast.error("Invalid Otp");
        }
      }
    } catch (error) {
      console.log("error occured entering otp", error);
    }
  };

  const otpInputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (value, index) => {
    // Update the state based on the input value
    switch (index) {
      case 0:
        setOtp1(value);
        break;
      case 1:
        setOtp2(value);
        break;
      case 2:
        setOtp3(value);
        break;
      case 3:
        setOtp4(value);
        break;
      default:
        break;
    }
    // Move focus to the next input if a digit is entered
    if (value.length === 1 && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  // email otp
  const handleEmailOtpChange = (value, index) => {
    // Update the state based on the input value
    switch (index) {
      case 0:
        setEmailOtp1(value);
        break;
      case 1:
        setEmailOtp2(value);
        break;
      case 2:
        setEmailOtp3(value);
        break;
      case 3:
        setEmailOtp4(value);
        break;
      default:
        break;
    }
    // Move focus to the next input if a digit is entered
    if (value.length === 1 && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && !e.target.value) {
      // Move focus to the previous input
      otpInputRefs[index - 1].current.focus();
    }
  };

  const handleLoginStartBack = () => {
    setLoginStart(true);
    setIsContinue(false);
    setLoginStartEmail(false);
    setForgotPassword(false);
    // setFormDataEmail({
    //   email: "",
    //   password: "",
    // });
  };

  const handleSignUpStartBack = () => {
    setLoginStart(true);
    setIsContinue(false);
    setLoginStartEmail(false);
    setRegisterNew(false);
    setForgotPassword(false);
    setIsOtpForgot(false);
    setLoginNewPassword(false);
  };

  // email condion when unable
  const handleLoinEmailCondition = () => {
    setLoginStartEmail(true);
    setLoginStart(false);
    setIsContinue(false);
    setRegisterNew(false);
    setForgotPassword(false);
    setLoginNewPassword(false);
  };
  // register condion when unable
  const handleLoinRegisterCondition = () => {
    setRegisterNew(true);
    setLoginStartEmail(false);
    setLoginStart(false);
    setIsContinue(false);
    setForgotPassword(false);
    setIsOtpForgot(false);
    // setFormDataEmail({
    //   email: "",
    //   password: "",
    // });
    // setFormDataRegister({
    //   fname: "",
    //   lname: "",
    //   mobile: "",
    //   email: "",
    //   password: "",
    //   referral: "",
    // });
  };

  // show forogot password pop up
  const showForgotPasswordPopup = () => {
    setForgotPassword(true);
    setRegisterNew(false);
    setLoginStartEmail(false);
    setLoginStart(false);
    setIsContinue(false);
    setIsOtpForgot(false);
    setLoginNewPassword(false);
    setEmailForgotPassword("");
    setEmailOtp1("");
    setEmailOtp2("");
    setEmailOtp3("");
    setEmailOtp4("");
  };

  // show forogot password pop up
  // const handleForgotPassword = () => {
  //   setForgotPassword(false);
  //   setIsOtpForgot(true);
  // };

  const handleForgotPassword = async (e) => {
    e && e.preventDefault();
    // Validate email
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
      emailForgotPassword
    );

    if (emailForgotPassword === "") {
      toast.error("Email is required!");
      return;
    } else if (!isValidEmail) {
      toast.error("Please enter a valid email.");
      return;
    }
    const finalData = { email: emailForgotPassword };
    try {
      const response = await axios.post(
        API_BASE_URL + "user/forget_password_send_otp",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        setForgotPassword(false);
        setIsOtpForgot(true);
        setEmailOtp(response.data.data);
        resetTimer();
      } else {
        if (response.data.status === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log("error occured login phone number", error);
    }
  };

  // verify otp for registraction
  const handleSendOtpRegistraction = async (e) => {
    e.preventDefault();
    if (!checkHandleValidateRegister()) {
      return;
    }

    if (formDataRegister.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    const finalData = { email: formDataRegister.email };
    try {
      const response = await axios.post(
        API_BASE_URL + "user/registration_send_otp",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        setRegisterOtp(true);
        // all pop
        setRegisterNew(false);
        setIsOtpForgot(true);
        toast.success(response.data.message);
        setEmailOtp(response.data.data);
        resetTimer();
      } else {
        if (response.data.status === false) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log("error occured login phone number", error);
    }
  };
  // verify otp and navigate new password and confirm password
  const handleVerifyForgotOtp = (e) => {
    e.preventDefault();
    const enteredOTP =
      `${emailotp1}${emailotp2}${emailotp3}${emailotp4}`.trim();
    const serverOTPAsString = String(emailOtp);
    console.log("enteredOTP", enteredOTP);
    console.log("serverOTPAsString", serverOTPAsString);
    if (enteredOTP === serverOTPAsString) {
      if (registerOtp || checkRegisterOtp) {
        handleSubmitRegister(e);
      } else {
        setIsOtpForgot(false);
        setLoginNewPassword(true);
      }
      setEmailOtp1("");
      setEmailOtp2("");
      setEmailOtp3("");
      setEmailOtp4("");
    } else {
      toast.error("Invalid Otp!");
    }
  };

  // using new password login
  const handleNewPasswordEmail = async (event) => {
    event.preventDefault();
    if (newPassword === "") {
      toast.error("Password must be required!");
      return;
    } else if (newPassword.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    } else if (confirmPassword !== newPassword) {
      toast.error("Both Password fields are not the same.");
      return;
    }
    try {
      const finalData = {
        email: emailForgotPassword,
        new_password: newPassword,
      };
      const response = await axios.post(
        API_BASE_URL + "user/reset_password",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleLoinEmailCondition();
          setFormDataEmail({
            email: "",
            password: "",
          });
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const checkHandleValidateSubmit = () => {
    let valid = true;
    if (formData.fname === "") {
      toast.error("Name Must be Require !");
      valid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.fname)) {
      toast.error("Name should only contain letters !");
      valid = false;
    } else if (formData.lname === "") {
      toast.error("Last Name Must be Require !");
      valid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.lname)) {
      toast.error("Last Name should only contain letters !");
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkHandleValidateSubmit()) {
      return;
    }

    const { fname, lname, email } = formData;
    const firebaseToken = sessionStorage.getItem("firebaseToken");
    const finalData = {
      user_name: fname,
      last_name: lname,
      user_email: email,
      user_mobile: phone,
      session_id: localStorage.getItem("session_id"),
      firebase_token: firebaseToken,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "user/register",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        // Registration successful, display success message
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        const lastVisitedPage = localStorage.getItem("lastVisitedPage");
        toast.success(response.data.message);
        setTimeout(() => {
          onClose(); // Close the modal
          if (lastVisitedPage) {
            navigate(lastVisitedPage);
          } else {
            navigate("/"); // Navigate to the home page if no last visited page is found
          }
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occured registration", error);
    }
  };

  // using email id login
  const handleLoginEmail = async (event) => {
    event.preventDefault();
    const { email, password } = formDataEmail;
    if (email === "") {
      toast.error("Email must be required!");
      return;
    } else if (!isValidEmail(formDataEmail.email)) {
      toast.error("Please enter a valid email address!");
      return;
    } else if (password === "") {
      toast.error("Password must be required!");
      return;
    }

    try {
      const firebaseToken = sessionStorage.getItem("firebaseToken");
      const finalData = {
        user_email: email,
        password: password,
        session_id: localStorage.getItem("session_id"),
        firebase_token: firebaseToken,
      };
      console.log("login", finalData);
      const response = await axios.post(
        API_BASE_URL + "user/login",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        localStorage.removeItem("cartItems");
        toast.success(response.data.message);
        const lastVisitedPage = localStorage.getItem("lastVisitedPage");
        setTimeout(() => {
          onClose();
          if (checkRegisterOtp) {
            navigate("/");
          } else if (lastVisitedPage) {
            navigate(lastVisitedPage);
          } else {
            navigate("/"); // Navigate to the home page if no last visited page is found
          }
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const isValidEmail = (email) => {
    // Basic email format validation using regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  // user register new without otp
  // handle form on submit

  const checkHandleValidateRegister = () => {
    let valid = true;
    if (formDataRegister.fname === "") {
      toast.error("Name Must be Require !");
      valid = false;
    } else if (formDataRegister.lname === "") {
      toast.error("Last Name Must be Require !");
      valid = false;
    } else if (
      formDataRegister.mobile !== "" &&
      formDataRegister.mobile.length !== 10
    ) {
      toast.error("Number must be 10 digits.");
      valid = false;
    } else if (!isValidEmail(formDataRegister.email)) {
      toast.error("Please Enter Valid Email Address");
      valid = false;
    }
    // else if (formDataRegister.mobile.length !== 10) {
    //   toast.error("Number Must 10 Digits");
    //   valid = false;
    // }
    return valid;
  };

  const handleSubmitRegister = async (event) => {
    const { fname, lname, mobile, email, password, referral } =
      formDataRegister;
    event.preventDefault();
    if (!checkHandleValidateRegister()) {
      return;
    }
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }
    try {
      const firebaseToken = sessionStorage.getItem("firebaseToken");
      const finalData = {
        user_name: fname,
        last_name: lname,
        user_mobile: mobile,
        user_email: email,
        password: password,
        referral: referral,
        session_id: localStorage.getItem("session_id"),
        firebase_token: firebaseToken,
      };
      const response = await axios.post(
        API_BASE_URL + "user/register_by_email",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      if (response.data.status === true) {
        // Registration successful, display success message
        // toast.success(response.data.message);
        // setIsOtpForgot(false);
        // handleLoinEmailCondition();
        // setTimeout(() => {
        //   setFormDataEmail({
        //     email: "",
        //     password: "",
        //   });
        // }, 2000);
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        const lastVisitedPage = localStorage.getItem("lastVisitedPage");
        toast.success(response.data.message);
        setTimeout(() => {
          onClose(); // Close the modal
          if (lastVisitedPage) {
            navigate(lastVisitedPage);
          } else {
            navigate("/"); // Navigate to the home page if no last visited page is found
          }
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  // for register user navigate to back if register otp is here else close pop up

  const backVerifyOtpPopUp = () => {
    if (registerOtp) {
      handleLoinRegisterCondition();
    } else {
      showForgotPasswordPopup();
    }
    if (checkRegisterOtp) {
      handleClose();
    }
  };

  // for register user navigate to back if register otp is here else close pop up

  const changeVerifyOtpPopUp = () => {
    if (registerOtp) {
      handleLoinRegisterCondition();
    } else {
      showForgotPasswordPopup();
      resetTimer();
    }
    if (checkRegisterOtp) {
      handleClose();
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>Decorsaga Design | Login</title>
        <meta
          name="description"
          content=": Login to your Decorsaga Design's account to access your order history, track shipments
              & manage your preferences
              "
        />
        <meta
          name="keywords"
          content=" Login, Sign in, account access, user authentication, Decorsaga design, furniture,
              accessories, home decor"
        />
        
      </Helmet> */}
      <div className={`custom-modal ${isOpen ? "show" : ""}`}>
        <div className="custom-modal-content">
          {/* <button>Click me</button> */}
          <span className="close-button" onClick={handleClose}>
            <img
              src={require("../assets/images/close.png")}
              className="img-fluid"
              alt=""
            />
          </span>
          <div className="why_choose_big_main">
            <div className="why_choose_big">
              {/* <h6 className="heading_log">why decorSaga?</h6>
              <div className="why_box mt-3">
                <div className="box_under_img">
                  <img
                    src={require("../assets/images/qty.png")}
                    className="img-fluid"
                    alt=""
                    style={{ filter: "contrast(0.3)" }}
                  />
                  <p className="img_text">quality</p>
                </div>
                <div className="box_under_img">
                  <img
                    src={require("../assets/images/time_load.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <p className="img_text">on time</p>
                </div>
              </div>
              <div className="why_box bd_cg mt-3">
                <div className="box_under_img">
                  <img
                    src={require("../assets/images/search.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <p className="img_text" style={{ width: "77px" }}>
                    return policy
                  </p>
                </div>
                <div className="box_under_img">
                  <img
                    src={require("../assets/images/transport.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <p className="img_text" style={{ width: "77px" }}>
                    free delivery
                  </p>
                </div>
              </div> */}
              <Suspense fallback={<BankOfferSke />}>
                <LoginBanner isOpen={isOpen} />
              </Suspense>
            </div>
            {loginStart && (
              <div className="Login_Form">
                <h6 className="login_heading">login/sign up</h6>
                <p className="otp_heading">using OTP </p>
                <form onSubmit={handleLoginStart}>
                  <div className="form-group custom-login-f-g">
                    <input
                      type="tel" // Consider changing the type to 'tel' for phone numbers
                      placeholder="Enter Mobile Number"
                      className="form-control email_login_input"
                      id="phone"
                      maxLength={10}
                      value={phone}
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setPhone(input);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group custom-login-f-g continue_btn">
                    <button type="submit" className="btn_continue form-control">
                      Continue
                    </button>
                  </div>
                </form>

                {/* <div className="google_logo">
                <div className="google_right_bd">
                  <img
                    src={require("../assets/images/google.png")}
                    className="img-fluid me-3"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={require("../assets/images/facebook.png")}
                    className="img-fluid me-3"
                    alt=""
                  />
                </div>
              </div> */}
                <div className="footer_text">
                  <p className="text_ft">
                    Login using{" "}
                    <Link
                      to="#"
                      onClick={handleLoinEmailCondition}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      E-mail
                    </Link>{" "}
                    or{" "}
                    <Link
                      to="#"
                      onClick={handleLoinRegisterCondition}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Sign Up
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer_text">
                  <p className="text_ft">
                    By continuing, I accept TCP-Decorsaga{" "}
                    <Link
                      // to={`/policy/${base64.encode("1").split("=").join("")}/terms-conditions`}
                      to={`/policy/${base64
                        .encode("1")
                        .split("=")
                        .join("")}/terms-conditions`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      // to={`/policy/${base64.encode("2").split("=").join("")}/privacy-policy`}
                      to={`/policy/${base64
                        .encode("2")
                        .split("=")
                        .join("")}/privacy-policy`}
                      onClick={onClose}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Privacy Policy.
                    </Link>{" "}
                  </p>
                </div>
              </div>
            )}

            {/* email login  */}
            {loginStartEmail && (
              <div className="Login_Form_Email">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={handleLoginStartBack}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="login_heading">login</h6>
                </div>
                <p className="otp_heading">using Email</p>
                <form onSubmit={handleLoginEmail}>
                  <div className="form-group custom-login-f-g-email">
                    <input
                      placeholder="E-mail Address"
                      className="form-control email_login_input"
                      id="email"
                      type="text"
                      name="email"
                      value={formDataEmail.email}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group position-relative  custom-login-f-g-email">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control email_login_input"
                      placeholder="Password"
                      autoComplete="off"
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                    <div className="forgot_div">
                      <Link
                        className="text_ft_email ft_password"
                        onClick={showForgotPasswordPopup}
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>

                  <div className="form-group custom-login-f-g-email continue_btn_email">
                    <button type="submit" className="btn_continue form-control">
                      Login
                    </button>
                  </div>
                </form>
                <div className="footer_text_email">
                  <p className="text_ft_email">
                    Login using{" "}
                    <Link
                      to="#"
                      onClick={handleLoginStartBack}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Mobile
                    </Link>{" "}
                    or{" "}
                    <Link
                      to="#"
                      onClick={handleLoinRegisterCondition}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Sign Up
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer_text_email">
                  <p className="text_ft_email">
                    By continuing, I accept TCP-Decorsaga{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("1")
                        .split("=")
                        .join("")}/terms-conditions`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("2")
                        .split("=")
                        .join("")}/privacy-policy`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Privacy Policy.
                    </Link>{" "}
                  </p>
                </div>
              </div>
            )}
            {/* email login end */}
            {/* otp form  */}
            {isContinue && (
              <div className="Login_Form_second">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={handleLoginStartBack}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="otp_heading1 text-white text-center">
                    Verify Mobile Number
                  </h6>
                </div>
                <div className="otp_format">
                  <p className="otp_text">
                    Please check the OTP sent to your mobile number
                  </p>
                  <div className="number_changing_Form">
                    <h6 className="number_heading">{phone}</h6>
                    <Link
                      onClick={() => {
                        handleLoginStartBack();
                        resetTimer();
                      }}
                      className="change_mobile"
                    >
                      Change
                    </Link>
                  </div>
                  <form action="" onSubmit={handleVerifyOtp}>
                    <div className="enter_otp_format">
                      <h6 className="enter_otp_text">Enter OTP</h6>
                      <div className="otp_input_format">
                        {[otp1, otp2, otp3, otp4].map((otp, index) => (
                          <input
                            key={index}
                            ref={otpInputRefs[index]}
                            type="text"
                            value={otp}
                            onChange={(e) =>
                              handleOtpChange(e.target.value, index)
                            }
                            onKeyDown={(e) => handleBackspace(index, e)}
                            className="otp_input"
                            maxLength={1}
                            autoFocus={index === 0}
                          />
                        ))}
                      </div>
                      <div className="resend_format mt-3">
                        {timer <= 0 && (
                          <button
                            onClick={() => {
                              handleLoginStart();
                              resetTimer();
                            }}
                            className="change_mobile"
                            disabled={otp && timer !== 0 ? "disabled" : ""}
                          >
                            Resend OTP
                          </button>
                        )}
                        {timer > 0 && (
                          <p className="text-white ">Resend otp after</p>
                        )}
                        {timer > 0 && (
                          <p className="text-white ">{formatTime(timer)}</p>
                        )}
                        {/* {timer === 0 && (
                          <p className="text-white ms-3">Time's up!</p>
                        )} */}
                      </div>
                    </div>
                    <div className="btn_mt border-0">
                      {timer && (
                        <p className="send_otp">✔ Send OTP Successfully</p>
                      )}
                      {timer ? (
                        <button
                          type="submit"
                          className="btn_continue form-control mt-3"
                        >
                          Verify & Continue
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn_continue form-control mt-1 mb-2"
                        >
                          Verify & Continue
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* enter profile details */}
            {verifyOtp && (
              <div className="Login_Form_third_details">
                <h6 className="login_heading">Sign Up</h6>
                <p className="otp_heading"> Fill Details</p>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex gap-3">
                    <div className="form-group1">
                      <input
                        type="text"
                        name="fname"
                        placeholder="First Name"
                        className="form-control email_login_input"
                        id="email"
                        value={formData.fname}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group1">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        className="form-control email_login_input"
                        id="email"
                        value={formData.lname}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group1">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email (Optional)"
                      className="form-control email_login_input"
                      id="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group custom-login-f-g submit_pb">
                    <button className="btn_continue form-control">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
            {/*when register then enter profile details */}
            {registerNew && (
              <div className="Login_Form_third">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={handleSignUpStartBack}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="login_heading">Sign Up</h6>
                </div>
                <p className="otp_heading"> Fill Details</p>
                <form
                  onSubmit={
                    handleSendOtpRegistraction
                    // handleSubmitRegister
                  }
                >
                  <div className="d-flex gap-3">
                    <div className="form-group2">
                      <input
                        type="text"
                        name="fname"
                        placeholder="First Name"
                        className="form-control email_login_input"
                        id="fname"
                        value={formDataRegister.fname}
                        onChange={handleChangeRegister}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group2">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        className="form-control email_login_input"
                        id="lname"
                        value={formDataRegister.lname}
                        onChange={handleChangeRegister}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group2">
                    <input
                      type="tel" // Consider changing the type to 'tel' for phone numbers
                      placeholder="Mobile No (optional)"
                      className="form-control email_login_input"
                      id="mobile"
                      name="mobile"
                      maxLength={10}
                      value={formDataRegister.mobile}
                      onChange={handleChangeRegister}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group2">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control email_login_input"
                      id="email"
                      value={formDataRegister.email}
                      onChange={handleChangeRegister}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group2">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formDataRegister.password}
                      onChange={handleChangeRegister}
                      className="form-control email_login_input"
                      placeholder="Password"
                      autoComplete="off"
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button-register"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                  </div>
                  <div className="form-group custom-login-f-g-register submit_pb_register">
                    <button className="btn_continue form-control">
                      Send OTP
                    </button>
                  </div>
                </form>
              </div>
            )}

            {/* email send otp input forgot password */}
            {forgotPassword && (
              <div className="Login_Form">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={handleLoinEmailCondition}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="login_heading">Forgot Password</h6>
                </div>
                {/* <h6 className="login_heading"></h6> */}
                <p className="otp_heading">using OTP </p>
                <form onSubmit={handleForgotPassword}>
                  <div className="form-group custom-login-f-g">
                    <input
                      type="text"
                      placeholder="Enter Email Addresss"
                      className="form-control email_login_input"
                      id="emailOtp"
                      value={emailForgotPassword}
                      onChange={(e) => {
                        setEmailForgotPassword(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group custom-login-f-g continue_btn">
                    <button type="submit" className="btn_continue form-control">
                      Continue
                    </button>
                  </div>
                </form>
                <div className="footer_text">
                  <p className="text_ft">
                    Login using{" "}
                    <Link
                      to="#"
                      onClick={handleLoinEmailCondition}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      E-mail
                    </Link>{" "}
                    or{" "}
                    <Link
                      to="#"
                      onClick={handleLoinRegisterCondition}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Sign Up
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer_text">
                  <p className="text_ft">
                    By continuing, I accept TCP-Decorsaga{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("1")
                        .split("=")
                        .join("")}/terms-conditions`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("2")
                        .split("=")
                        .join("")}/privacy-policy`}
                      onClick={onClose}
                      className="text_ft"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Privacy Policy.
                    </Link>{" "}
                  </p>
                </div>
              </div>
            )}

            {/* using email address enter otp */}
            {isOtpForgot && (
              <div className="Login_Form_second">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={backVerifyOtpPopUp}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="otp_heading1 text-white text-center">
                    Verify Email Address
                  </h6>
                </div>
                <div className="otp_format">
                  <p className="otp_text">
                    Please check the OTP sent to your email.
                  </p>
                  <div className="number_changing_Form">
                    <h6 className="number_heading">{phone}</h6>
                    <Link
                      onClick={changeVerifyOtpPopUp}
                      className="change_mobile"
                    >
                      Change
                    </Link>
                  </div>
                  <form action="" onSubmit={handleVerifyForgotOtp}>
                    <div className="enter_otp_format">
                      <h6 className="enter_otp_text">Enter OTP</h6>
                      <div className="otp_input_format">
                        {[emailotp1, emailotp2, emailotp3, emailotp4].map(
                          (otp, index) => (
                            <input
                              key={index}
                              ref={otpInputRefs[index]}
                              type="text"
                              value={otp}
                              onChange={(e) =>
                                handleEmailOtpChange(e.target.value, index)
                              }
                              onKeyDown={(e) => handleBackspace(index, e)}
                              className="otp_input"
                              maxLength={1}
                              autoFocus={index === 0}
                            />
                          )
                        )}
                      </div>
                      <div className="resend_format mt-3">
                        {timer <= 0 && (
                          <button
                            onClick={(e) => {
                              registerOtp
                                ? handleSendOtpRegistraction(e)
                                : handleForgotPassword(e);
                              resetTimer();
                              setEmailOtp1("");
                              setEmailOtp2("");
                              setEmailOtp3("");
                              setEmailOtp4("");
                            }}
                            className="change_mobile"
                            disabled={emailOtp && timer !== 0 ? "disabled" : ""}
                          >
                            Resend OTP
                          </button>
                        )}
                        {timer > 0 && (
                          <p className="text-white ">Resend otp after</p>
                        )}
                        {timer > 0 && (
                          <p className="text-white ">{formatTime(timer)}</p>
                        )}
                        {/* {timer === 0 && (
                          <p className="text-white ms-3">Time's up!</p>
                        )} */}
                      </div>
                    </div>
                    <div className="btn_mt border-0">
                      {timer && (
                        <p className="send_otp">✔ Send OTP Successfully</p>
                      )}
                      {timer ? (
                        <button
                          type="submit"
                          className="btn_continue form-control mt-3"
                        >
                          Verify & Continue
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn_continue form-control mt-1 mb-2"
                        >
                          Verify & Continue
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* new password and confirm password */}
            {/* email login  */}
            {loginNewPassword && (
              <div className="Login_Form_Email">
                <div
                  className="icon_heading"
                  style={{ cursor: "pointer" }}
                  onClick={showForgotPasswordPopup}
                >
                  <ArrowBackIosIcon className="text-white" />
                  <h6 className="login_heading">Reset Password</h6>
                </div>
                <p className="otp_heading">using new password</p>
                <form onSubmit={handleNewPasswordEmail}>
                  <div
                    className="form-group position-relative custom-login-f-g-email"
                    style={{ marginTop: "20px" }}
                  >
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="password"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control email_login_input"
                      placeholder="New Password"
                      autoComplete="off"
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button-forgot"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                  </div>
                  <div className="form-group position-relative custom-login-f-g-email">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control email_login_input"
                      placeholder="Confirm Password"
                      autoComplete="off"
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                  </div>

                  <div className="form-group custom-login-f-g-email continue_btn_email">
                    <button type="submit" className="btn_continue form-control">
                      Submit
                    </button>
                  </div>
                </form>
                <div className="footer_text_email">
                  <p className="text_ft_email">
                    By continuing, I accept TCP-Decorsaga{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("1")
                        .split("=")
                        .join("")}/terms-conditions`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={`/policy/${base64
                        .encode("2")
                        .split("=")
                        .join("")}/privacy-policy`}
                      onClick={onClose}
                      className="text_ft_email"
                      style={{ backgroundColor: "#0000" }}
                    >
                      Privacy Policy.
                    </Link>{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        /> */}
      </div>
    </>
  );
};

export default CustomLoginModal;
