import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const userDetails = sessionStorage.getItem("info");
  const [searchHistory, setSearchHistory] = useState([]);
  const userInfo = JSON.parse(userDetails);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [wishListCount, setWishListCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [blogsData, setBlogsData] = useState([]);
  const [blogsHeader, setBlogsHeader] = useState([]);
  const userDataInfo = sessionStorage.getItem("info");
  const userDataDetails = JSON.parse(userDataInfo);

  const [walletBalance, setWalletBalance] = useState(
    userDataDetails ? userDataDetails.wallet : 0
  );

  // Authentication information
  const [isLoggedIn, setIsLoggedIn] = useState(!!userInfo); // Check if user is logged in
  const [user, setUser] = useState(userInfo);

  useEffect(() => {
    cartList();
  }, []);

  const cartList = async () => {
    var userId = 0;
    if (sessionStorage.getItem("info") != null) {
      userId = userInfo.user_id;
    }
    const data = {
      user_id: userId,
      session_id: localStorage.getItem("session_id"),
    };
    try {
      const response = await axios.post(`${API_BASE_URL}user/cart_list`, data, {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      // console.log("cart list search",response.data.data)
      if (sessionStorage.getItem("info") === null) {
        localStorage.setItem("cartItems", JSON.stringify(response.data.data));
      }
      setCartItems(response.data.data);
      setCartCount(response.data.data.length);
      setIsLoading(false);
    } catch (error) {
      console.error("Error adding to cart:", error.response.data.error);
    }
  };

  // blog get api
  useEffect(() => {
    const handleBlogList = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "user/blog_list", {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        // console.log("blog list", response.data);
        setBlogsHeader(response.data.blog_header);
        setBlogsData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:");
      }
    };
    handleBlogList();
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchSuggestions,
        setSearchSuggestions,
        cartCount,
        setCartCount,
        wishListCount,
        setWishListCount,
        activeFilters,
        setActiveFilters,
        cartItems,
        setCartItems,
        isLoading,
        cartList,
        // updateCartCount,
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        blogsData,
        walletBalance,
        setWalletBalance,
        blogsHeader,
        searchHistory,
        setSearchHistory,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};
export default SearchContext;
