// Skeleton.js
import React from "react";
import style from "./backOfferSke.module.css";

const BankOfferSke = () => {
  return (
    <section className={`${style.skeleton_main}`}>
      <div className={style["loader"]}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
  );
};

export default BankOfferSke;
